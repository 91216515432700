/* eslint-disable no-undef */
// const API = process.env
const routes = {
  GET_SUBSCRIPTION: "/subscription",
  GET_SUBSCRIPTION_INVOICES: "/subscription/invoices",
  UPDATE_SUBSCRIPTION: "/subscription",
}

function getUrl(route) {
  return `${process.env.API}${route}`
}

class API {
  static async getSubscription(token) {
    const res = await fetch(getUrl(routes.GET_SUBSCRIPTION), {
      credentials: "include",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const { subscription } = await res.json()
    return subscription
  }

  static async getInvoices(token) {
    const res = await fetch(getUrl(routes.GET_SUBSCRIPTION_INVOICES), {
      credentials: "include",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const { invoices } = await res.json()
    return invoices
  }

  static async updateSubscription(action, token) {
    const res = await fetch(getUrl(routes.UPDATE_SUBSCRIPTION), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      method: "PATCH",
      body: JSON.stringify({ action }),
    })
    const result = await res.json()
    return result
  }
}

export default API
