<script>
  import { link as routerLink } from "svelte-spa-router";

  export let handleClick = () => {};
  export let primary = false;
  export let link = "";
  export let href = "";
</script>

<style>
  button,
  a {
    display: block;
    width: auto;
    margin: 0.5rem;
    padding: 0.5rem 0.7rem;
    border-radius: 0.5rem;
    border: none;
    color: #fff;
    background-color: var(--primary);
    cursor: pointer;
  }

  a {
    display: inline-block;
    text-align: center;
  }

  .primary {
    display: block;
    margin: 1rem auto;
    padding: 1.5rem 2rem;
    width: 90%;
    max-width: 200px;
    background-color: var(--primary);
    border: none;
    border-radius: 20px;
    /* font-weight: bold; */
    font-size: 1.5rem;
    color: #fff;
  }

  a:hover {
    text-decoration: none;
  }
</style>

{#if link}
  <a class={`${primary && 'primary'}`} href={link} use:routerLink>
    <slot />
  </a>
{:else if href}
  <a
    class={`${primary && 'primary'}`}
    {href}
    target="blank"
    rel="noopener noreferer">
    <slot />
  </a>
{:else}
  <button class={`${primary && 'primary'}`} on:click={handleClick}>
    <slot />
  </button>
{/if}
