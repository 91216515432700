<script>
  import { onMount } from "svelte"
  import API from "../api"

  import Loader from "../components/Loader.svelte"
  import Button from "../components/Button.svelte"
  import Heading from "../components/Heading.svelte"
  import InvoiceCard from "../components/InvoiceCard.svelte"

  import SubscriptionCard from "../components/SubscriptionCard.svelte"

  let loading = true
  let invoices = []

  onMount(async () => {
    invoices = await API.getInvoices()
    loading = false
  })
</script>

<style>
  .buttons {
    display: flex;
    justify-content: center;
  }
  p {
    text-align: center;
  }
</style>

<Heading>Subscription Invoices</Heading>

{#if loading}
  <Loader />
{:else if invoices.length}
  {#each invoices as invoice}
    <InvoiceCard {invoice} />
  {/each}
{:else}
  <p>No invoices.</p>
{/if}

<div class="buttons">
  <Button link="/manage-subscription">Manage Subscription</Button>
</div>
