import Cancel from "./routes/Cancel.svelte"
import ErrorPage from "./routes/Error.svelte"
import Failure from "./routes/Failure.svelte"
import Home from "./routes/Home.svelte"
import Invoices from "./routes/Invoices.svelte"
import DraftInvoice from "./routes/DraftInvoice.svelte"
import ManageSubscription from "./routes/ManageSubscription.svelte"
import NewSubscription from "./routes/NewSubscription.svelte"
import Success from "./routes/Success.svelte"

const routes = {
	"/cancel": Cancel,
	"/": Home,
	"/invoices": Invoices,
	"/invoice-draft": DraftInvoice,
	"/manage-subscription": ManageSubscription,
	// "/2": ManageSubscription,
	"/new-subscription*": NewSubscription,
	"/new-subscription/*": NewSubscription,
	"/success*": Success,
	"/failure*": Failure,

	// Catch-all
	"*": ErrorPage,
}

export default routes
