<script>
  import { onMount } from "svelte"
  import { querystring } from "svelte-spa-router"
  import { parse } from "qs"

  import Heading from "../components/Heading.svelte"
  import Loader from "../components/Loader.svelte"
  // import BlackFridayHeader from "../components/BlackFridayHeader.svelte"
  import CardPricing from "../components/CardPricing.svelte"
  import Switch from "../components/Switch.svelte"

  import { plans } from "../plans"

  const stripe = Stripe(process.env.STRIPE_PUBLISHABLE_KEY)

  let showYearlyPlans = false

  let initialLoading = true
  let loading = false
  let redirectError = null
  let selectedPrice = ""
  let token = ""

  $: queryParams = parse($querystring)

  onMount(async () => {
    const startTime = Date.now()
    token = queryParams.token

    try {
      const { company, portalUrl } = await (
        await fetch(`${process.env.API}/subscription/offer`, {
          method: "POST",
          body: JSON.stringify({}),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
      ).json()

      if (!company) {
        throw Error('Please contact support (issue: "params not matching")')
      }
      if (portalUrl) {
        window.location = portalUrl
        return
      }
    } catch (e) {
      console.error(e)
      redirectError = e.message || e
    } finally {
      const minLoadingTime = 2 * 1000

      // Check loading time, if less than 'minLoadingTime', add fake delay
      const endTime = Date.now()
      const timeDiff = endTime - startTime
      const timeout = timeDiff < minLoadingTime ? minLoadingTime - timeDiff : 0

      setTimeout(() => {
        initialLoading = false
      }, timeout)
    }
  })

  async function subscribe(priceName) {
    selectedPrice = priceName
    loading = true

    console.log("subscribe", priceName)

    try {
      const result = await (
        await fetch(`${process.env.API}/createCheckoutSession`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            planId: selectedPrice,
          }),
        })
      ).json()

      console.log("result", result)

      const res = await stripe.redirectToCheckout({
        sessionId: result.sessionId,
      })
      console.log("redirect", res)
      if (res.error) redirectError = res.error.message
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `res.error.message`.
    } catch (e) {
      redirectError = e
    } finally {
      loading = false
    }
  }
</script>

{#if initialLoading}
  <Loader />
{/if}

{#if !initialLoading}
  <!-- <BlackFridayHeader /> -->
  <Heading>Pick a plan</Heading>

  <section class="periodSwitch">
    Monthly
    <Switch bind:checked={showYearlyPlans} />
    Yearly
  </section>

  <section>
    {#if redirectError}
      <p class="error">{redirectError}</p>
    {/if}
  </section>

  <section class="prices">
    {#each plans[showYearlyPlans ? "yearly" : "monthly"] as plan}
      <CardPricing
        id={plan.id}
        price={plan.price}
        period={showYearlyPlans ? "year" : "month"}
        staff={plan.staff}
        basePrice={plan.basePrice}
        selectPrice={subscribe}
        {loading}
      />
    {/each}
  </section>
{/if}

<style>
  .periodSwitch {
    width: 100%;
    margin: 2rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;

    font-weight: bold;
  }

  .prices {
    margin: 0 auto;
    max-width: 800px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }

  .subtitle {
    text-align: center;
    margin-bottom: 3rem;
  }

  .error {
    text-align: center;
    padding: 1rem;
    max-width: 300px;
    margin: 0 auto 4rem;
    font-size: 0.9rem;
    background-color: pink;
    border: 1px solid red;
  }

  @media (max-width: 550px) {
    .prices {
      grid-template-columns: 1fr;
    }
  }
</style>
