<script>
  import { onMount } from "svelte"
  import { querystring } from "svelte-spa-router"
  import { format, fromUnixTime } from "date-fns"

  let loading = true
  let error = ""
  let invoice = {
    company: {},
    customer: {},
  }
  let { company, customer } = invoice
  let token = ""

  onMount(async () => {
    const urlParams = new URLSearchParams($querystring)
    token = urlParams.get("token")
    await fetchData()
  })

  const fetchData = async () => {
    try {
      loading = true
      error = null
      if (!token) {
        throw Error("Missing token")
      }
      const res = await fetch(`${process.env.API}/invoice/${id}?include=company`, {
        credentials: "include",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const data = await res.json()
      if (!data.success) {
        throw Error(data.message)
      }
      invoice = data.invoice
      company = data.company || {}
      loading = false
    } catch (e) {
      console.log(e)
      error = e.message
      loading = false
    }
  }

  const formatPrice = (price) => (price / 100).toFixed(2)
</script>

<section class="container">
  {#if error}
    <h1>Ooops...</h1>
    <p>{error}</p>
    <button on:click={fetchData}>Try again</button>
  {:else if loading}
    <h1>Loading...</h1>
  {:else}
    <section class="columns">
      <div class="col">
        <h1 class="companyName">{company.name}</h1>
        <p class="address">
          {company.address}
          <br />
          {company.postcode}, {company.city}
        </p>
        <p class="address">
          <strong>Bill to</strong>
          <br />
          {invoice.customer_name}
          <br />
          {#if invoice.customer_address}
            {invoice.customer_address.line1}
            <br />
            {invoice.customer_address.postal_code}, {invoice.customer_address.city}
            <br />
          {/if}
          {invoice.customer_phone}
          <br />
          <a href={invoice.customer_email}>{invoice.customer_email}</a>
        </p>
      </div>
      <div class="col">
        <h1 class="invoiceTitle">Invoice</h1>
        <p class="details">
          <span class="details__title">Invoice Number</span>
          <span class="details__data">{invoice.number}</span>
        </p>
        <p class="details">
          <span class="details__title">Date of issue</span>
          <span class="details__data" />
        </p>
        <p class="details">
          <span class="details__title">Date due</span>
          <span class="details__data">
            {format(fromUnixTime(invoice.due_date), "LLL d, yyyy")}
          </span>
        </p>
      </div>
    </section>

    <h1 class="summary">
      £{formatPrice(invoice.total)} due {format(fromUnixTime(invoice.due_date), "LLLL d, yyyy")}
    </h1>

    <!-- TABLE -->

    <table>
      <thead>
        <tr>
          <th>Description</th>
          <th>Qty</th>
          <th>Unit price</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {#each invoice.lines.data as item}
          <tr>
            <td>{item.description}</td>
            <td>{item.quantity}</td>
            <td>£{formatPrice(item.amount)}</td>
            <td>£{formatPrice(item.amount * item.quantity)}</td>
          </tr>
        {/each}

        <tr class="table__footer table__footer--first">
          <td colspan="2" />
          <td>Subtotal</td>
          <td>£{formatPrice(invoice.subtotal)}</td>
        </tr>
        {#if invoice.tax}
          <tr class="table__footer">
            <td colspan="2" />
            <td>VAT (20% incl.)</td>
            <td>£{formatPrice(invoice.tax)}</td>
          </tr>
        {/if}
        <tr class="table__footer table__total">
          <td colspan="2" />
          <td>Amount due</td>
          <td>£{formatPrice(invoice.amount_due)}</td>
        </tr>
      </tbody>
    </table>

    <div class="line" />
    <p class="footerNotes">
      <strong>Pay £{formatPrice(invoice.total)} with card</strong>
      <br />
      Visit
      <a href="#">___payment url will be here___</a>
    </p>
  {/if}
</section>

<style>
  .container {
    position: absolute;
    left: 0;
    /* right: 0; */
    top: 0;
    /* bottom: 0; */
    background-color: #fff;
    max-width: 900px;
    width: 100%;
    padding: 2rem;
  }

  @media (min-width: 970px) {
    .container {
      position: static;
      margin: 0 auto;
      padding: 0;
      overflow: hidden;
    }
  }

  p,
  table {
    font-size: 1.2rem;
  }
  h1 {
    font-size: 2.5rem;
    color: var(--headingsColor);
    font-weight: 500;
  }
  button {
    display: block;
    padding: 1.5rem;
    border: none;
    border-radius: 1.5rem;
    background-color: var(--primary);
    color: #fff;
    font-size: 1.8rem;
    letter-spacing: 1px;
    cursor: pointer;
  }
  .columns {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .companyName {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  .invoiceTitle {
    margin-bottom: 2rem;
    font-size: 3rem;
    font-family: monospace;
    color: #8898aa;
    text-align: right;
  }
  .details {
    display: flex;
    justify-content: space-between;
    margin: 0.3rem;
  }
  .details__title {
    margin-right: 1rem;
  }
  .details__data {
    font-weight: bold;
    color: var(--headingsColor);
  }
  .address strong {
    color: var(--headingsColor);
  }
  table {
    width: 100%;
    margin-bottom: 10rem;
    border-collapse: collapse;
  }
  table td {
    padding: 1.5rem;
    border: none;
  }
  table th {
    text-align: left;
    padding: 1rem;
    border: none;
    border-bottom: 2px solid var(--gray);
    color: var(--headingsColor);
  }
  table tbody tr td:first-of-type {
    color: var(--headingsColor);
  }
  /* columns width */
  table tbody tr td:nth-of-type(1) {
    width: 55%;
  }
  table tbody tr td:nth-of-type(2) {
    width: 5%;
  }
  table tbody tr td:nth-of-type(3) {
    width: 30%;
  }
  table tbody tr td:nth-of-type(4) {
    width: 10%;
    font-weight: bold;
    color: var(--headingsColor);
  }
  table tbody tr:nth-of-type(odd) td {
    background-color: var(--lightGray);
  }
  .table__footer td:first-of-type {
    background-color: #fff;
  }
  .table__footer--first {
    border-top: 2px solid var(--gray);
  }
  .table__total td:not(:first-of-type) {
    border-top: 2px solid var(--gray);
    background-color: #e6ebf1;
  }
  .table__footer:last-of-type td {
    color: var(--headingsColor);
    font-weight: bold;
  }
  .line {
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--gray);
  }
  .footerNotes {
    margin-top: 2rem;
  }
  .footerNotes strong {
    color: var(--headingsColor);
    font-weight: 600;
    text-decoration: none;
  }
  .footerNotes a {
    font-weight: 600;
    text-decoration: none;
  }
</style>
