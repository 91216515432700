<script>
  import { format, fromUnixTime, formatDistanceToNow } from "date-fns"
  import Card from "../components/Card.svelte"

  export let subscription = {}
</script>

<style>
  ul {
    display: block;
    max-width: 350px;
    margin: 0 auto;
    padding: 0;
  }

  ul li {
    list-style-type: none;
    padding: 0.5rem 0.5rem;
  }

  .title {
    font-size: 1rem;
    font-weight: bold;
  }

  .price {
    display: block;
    float: right;
    font-weight: bold;
  }
</style>

<Card>
  <ul>
    <li>
      <span class="title">{subscription.plan.nickname}</span>
      <span class="price">£{Math.round(subscription.plan.amount / 100).toFixed(2)}/m</span>
    </li>
    <li>
      Status:
      <strong>{subscription.status.toUpperCase()}</strong>
    </li>
    {#if subscription.trial_end}
      <li>
        Trial ends:
        <strong>
          {formatDistanceToNow(fromUnixTime(subscription.trial_end), { addSuffix: true })}
        </strong>
      </li>
    {/if}
    {#if subscription.cancel_at}
      <li>
        Cancel at:
        <strong>{format(fromUnixTime(subscription.current_period_end), 'd MMMM yyyy')}</strong>
      </li>
    {:else}
      <li>
        Next payment:
        <strong>{format(fromUnixTime(subscription.current_period_end), 'd MMMM yyyy')}</strong>
      </li>
    {/if}
    {#if subscription.payment_method_data}
      <li>
        Credit Card:
        <strong>**** **** **** {subscription.payment_method_data.last4}</strong>
      </li>
    {/if}
  </ul>
</Card>
