<script>
  import { onMount } from "svelte"
  import LogRocket from "logrocket"
  import API from "../api"

  import Loader from "../components/Loader.svelte"
  import Button from "../components/Button.svelte"
  import Heading from "../components/Heading.svelte"

  import SubscriptionCard from "../components/SubscriptionCard.svelte"

  let stripe = {}
  let loading = true
  let subscription = {}

  onMount(async () => {
    stripe = Stripe(process.env.STRIPE_PUBLISHABLE_KEY)
    subscription = await API.getSubscription()
    console.log({ default_payment_method: subscription.default_payment_method })

    if (!process.env.IS_LOCAL) {
      LogRocket.identify(subscription.company, {
        company: subscription.company,
        stripeCustomer: subscription.customer
      })
    }

    loading = false
  })

  const updatePaymentMethod = async () => {
    loading = true
    const { session } = await API.updateSubscription("update_payment_method")
    const { error } = await stripe.redirectToCheckout({
      sessionId: session.id
    })
    loading = false
  }

  async function cancelSubscription() {
    loading = true
    const result = await API.updateSubscription("cancel")
    subscription = result.subscription
    console.log(subscription)
    loading = false
  }

  async function reactivateSubscription() {
    loading = true
    const result = await API.updateSubscription("reactivate")
    subscription = result.subscription
    loading = false
  }
</script>

<style>
  .buttons {
    display: flex;
    justify-content: center;
  }
</style>

<Heading>Manage Subscription</Heading>

{#if loading}
  <Loader />
{:else if subscription._id}
  <SubscriptionCard {subscription} />
  <div class="buttons">
    {#if !subscription.default_payment_method}
      <Button handleClick={updatePaymentMethod}>Add Payment Card</Button>
    {:else}
      <Button handleClick={updatePaymentMethod}>Update Payment Method</Button>
    {/if}

    {#if subscription.cancel_at_period_end}
      <Button handleClick={reactivateSubscription}>Reactivate</Button>
    {:else}
      <Button handleClick={cancelSubscription}>Cancel Subscription</Button>
    {/if}
  </div>
{:else}Loading error{/if}

<div class="buttons">
  <Button link="/invoices">View Subscription Invoices</Button>
</div>
