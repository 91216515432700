<script>
  import { format, fromUnixTime } from "date-fns"
  import Card from "../components/Card.svelte"
  import Button from "../components/Button.svelte"

  export let invoice = {}
</script>

<style>
  .line {
    max-width: 350px;
    padding: 0.5rem 0.5rem;
  }

  .title {
    font-size: 1rem;
    font-weight: bold;
  }

  .status {
    display: inline-block;
    padding: 0.2rem 0.3rem;
    background-color: #999;
    border-radius: 5px;
    color: #fff;
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
  }

  .price {
    display: block;
    float: right;
    font-weight: bold;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
  }
</style>

<Card>
  <div class="line">
    <span class="title">{format(fromUnixTime(invoice.created), 'd MMMM yyyy')}</span>
    <span class="status">{invoice.status}</span>
    <span class="price">£{Math.round(invoice.total / 100).toFixed(2)}</span>
  </div>
  <div class="buttonContainer">
    <Button href={invoice.hosted_invoice_url}>View Invoice</Button>
  </div>
</Card>
